import api from './api';

// 驳回商户申请
export const $sp_shopRefuse = (params) => api.post('/j/rebate/shop/refuse', params);
// 总平台分页查询联联团购
export const $sp_grouppage = (params) => api.postNoMessage('/sssk/api/platform/biz/platform/group/page', params);
// 总平台修改联联团购
export const $sp_groupupdate = (params) => api.postNoReject('/sssk/api/platform/biz/platform/group/update', params);
// 总平台根据联联产品id删除产品判断是否被购买
// export const $sg_orderStatics = (params) => api.get(`/j/platform/t-shop-info/order-statics/${params}`);
export const $sp_deletevalidate = (params) => api.get(`/sssk/api/platform/biz/platform/group/delete/validate/${params}`);
// 总平台根据联联产品id删除产品
export const $sp_groudelete = (params) => api.post('/sssk/api/platform/biz/platform/group/delete', params);
// 总平台上下架联联团购
export const $sp_groudline = (params) => api.post(`/sssk/api/platform/biz/platform/group/line?${params}`, params);
// 查询单个团购详情
export const $sp_queryDetail = (params) => api.get('/sssk/api/platform/biz/platform/group/queryDetail', params);
