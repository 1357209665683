<template>
  <div class="groupBuyingList">
    <div class="flexbox flex-lr flex-align-center">
        <el-input v-model="form.groupName" placeholder="团购名称" style="width:200px;" clearable></el-input>
        <el-input v-model="form.merchantName" placeholder="商户名称" style="width:200px;margin-left:20px;" clearable></el-input>
        <!-- <el-input v-model="form.merchantAccount" placeholder="商户账号" style="width:200px;margin-left:20px;" clearable></el-input> -->
        <el-select v-model="form.onlineState" class="ml20 mr20" clearable placeholder="商品状态" style="width:150px;">
          <el-option
            v-for="item in stateOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <el-button type="primary" @click="search">筛选</el-button>  
        <el-button type="info" @click="clear">清空</el-button>

    </div>
    <div class="tips">注：请在团购中查询团购订单，分润中查看团购分润</div>
    <div class="tableBox">
      <el-table :data="tableData" style="width: 100%" border>
        <el-table-column prop="groupId" label="团购编号" align="center"></el-table-column>
        <el-table-column prop="merchantName" label="商户" align="center" :show-overflow-tooltip='true'></el-table-column>
        <el-table-column prop="groupName" label="团购名称" align="center" :show-overflow-tooltip='true'></el-table-column>
        <el-table-column prop="categoryName" label="团购分类" align="center"></el-table-column>
        <el-table-column prop="city" label="地区" align="center"></el-table-column>
        <el-table-column prop="salePrice" label="团购价格（元）" align="center"></el-table-column>
        <el-table-column prop="saleNum" label="销量（份）" align="center">
          <template slot-scope="scope">{{scope.row.saleNum || '-'}}</template>
        </el-table-column>
        <!-- <el-table-column prop="remainingStock" label="剩余库存" align="center">
          <template slot-scope="scope">{{scope.row.surplusStockCount || '-'}}</template>
        </el-table-column> -->
        <el-table-column prop="verifiedNum" label="已核销" align="center">  
          <template slot-scope="scope">{{scope.row.verifiedNum || '-'}}</template>
        </el-table-column>
        <el-table-column prop="expiredNum" label="已过期" align="center">
          <template slot-scope="scope">{{scope.row.expiredNum || '-'}}</template>
        </el-table-column>
        <el-table-column prop="sellState" label="状态" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.sellState === 1">未开始</span>
            <span v-if="scope.row.sellState === 2">已结束</span>
            <span v-if="scope.row.sellState === 3">进行中</span>
          </template>
        </el-table-column>
        <el-table-column prop="onlineState" label="上架/下架" align="center">
          <template slot-scope="scope">
            <el-button type="text" v-if="scope.row.onlineState === 1" @click="updateState(scope.row.groupId,0)">下架</el-button>
            <el-button type="text" v-if="scope.row.onlineState === 0" @click="updateState(scope.row.groupId,1)">上架</el-button>
            <span v-if="scope.row.onlineState!==0&&scope.row.onlineState!==1">-</span>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="操作" align="center">
          <template slot-scope="scope">
            <!-- <el-button type="text" v-if="scope.row.state === 1" @click="addGoods('2', scope.row.groupGoodsId)">编辑</el-button> -->
            <el-button type="text"  @click="compile('2', scope.row)">编辑</el-button>
            <el-button type="text" @click="addGoods('3', scope.row)">详情</el-button>
            <!-- <el-button type="text" @click="delect(scope.row)">删除</el-button> -->
            
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination class="mt10" background :page-count="totalPage"
                   :current-page.sync="currentPage" @current-change="pageChange">
    </el-pagination>
    <Dialog ref="delectDialog" dialogWidth="500px">
      <div v-if="!isBuying" class="dialogBtn flexbox flex-align-center flex-justify-center">
        <el-button class="btn" type="primary" @click="confirmDelect">确认</el-button>
        <el-button class="btn" type="info" @click="$refs.delectDialog.showDialog = false">取消</el-button>
      </div>
      <div v-else class="dialogBtn flexbox flex-align-center flex-justify-center">
        <el-button class="btn" type="primary" @click="$refs.delectDialog.showDialog = false">好的</el-button>
      </div>
    </Dialog>
        <!-- <aldialog  ref="detailPage"></aldialog> -->
  </div>
</template>
<script>
import {
  $sp_grouppage,
  // $sp_deletevalidate,
  $sp_groudelete,
  $sp_groudline,
} from '@/api/algroupBuying';

import Dialog from '@/components/common/dialog.vue';

export default {

  data() {
    return {
      isBuying: '', // 是否有购买数据
      delectGroupId: '', // 删除团购ID
      form: {
        groupName: '',
        merchantName: '',
        onlineState: '',
      },
      time: '',
      totalPage: 1,
      currentPage: 1,
      tableData: [],
      stateOptions: [
        {
          value: 0,
          label: '下架',
        },
        {
          value: 1,
          label: '上架',
        },
      ],
    };
  },
  components: {
    // Aldialog,
    Dialog,
  },
  created() {
    this.getTableData();
  },
  methods: {
    // 点击删除弹窗事件
    confirmDelect() {
      $sp_groudelete({
        groupGoodsId: this.delectGroupId,
      }).then((res) => {
        console.log(res);
        this.$message.success('删除成功');
      }).catch((err) => {
        console.log(err);
      });
      this.$refs.delectDialog.showDialog = false;
      this.getTableData();
    },
    // 筛选
    search() {
      this.currentPage = 1;
      this.getTableData();
    },
    // 清空
    clear() {
      this.form = {
        groupName: '',
        merchantName: '',
        onlineState: '',
      };
      this.currentPage = 1;
      this.getTableData();
    },
    // 请求列表数据
    getTableData() {
      const params = {
        pageCurrent: this.currentPage,
        pageSize: 15,
        ...this.form,
      };
      $sp_grouppage(params).then((res) => {
        this.tableData = res.records;
        this.totalPage = res.pages;
        console.log(res, '数据');
      });
    },
    // 更新团购商品上下架状态
    updateState(groupId, status) {
      const tips = status === 1 ? '上架' : '下架';
      this.$confirm(`确定要${tips}吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        showClose: false,
        closeOnClickModal: false,
      }).then(() => {
        $sp_groudline(`groupId=${groupId}&status=${status}`).then(() => {
          this.getTableData();
        });
      }).catch(() => {
        console.log('请求失败');
      });
    },
    // 跳转到详情商品页面
    addGoods(type, row) {
      // type: 类型， 1-新增，2-编辑，3-详情
      this.$router.push({
        name: 'AddGroupBuyingGoods',
        query: {
          row: JSON.stringify(row),
          type,
        },
      });
    },
    // 跳转到编辑页面
    compile(type, row) {
      console.log(type, row);
      // type: 类型， 1-新增，2-编辑，3-详情
      this.$router.push({
        name: 'AddGroupBuyingGoods',
        query: {
          row: JSON.stringify(row),
          type,
        },
      });
    },
    // 翻页
    pageChange() {
      this.getTableData();
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-tooltip__popper{max-width:80%}
.dialogBtn{
  width: 100%;
  margin-top: 60px;
  .btn {
    width: 200px;
    height: 50px;
    border-radius: 50px;
  }
}
.groupBuyingList{
  width: 100%;
  margin: 0;
  padding: 40px 20px;
  box-sizing: border-box;
  background-color: #fff;
    .tips {
      width: 100%;
      margin-top: 40px;
      font-size: 14px;
      color: #666;
    }
    .tableBox{
      width: 100%;
      margin-top: 10px;
    }
    .el-table{
      min-height: 580px;
      & > th {
        background-color: #EF3F46;
        color: #fff;
      }
    }
    
  }
</style>
