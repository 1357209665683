<template>
  <div class="share-profits-deatail">
    <el-form :inline="true" :model="formInline" class="demo-form-inline" style="width: 100%">
      <el-form-item label="归属分公司">
        <el-select v-model="formInline.spreadId" placeholder="请选择分公司" clearable filterable remote :loading="loading"
          :remote-method="searchBranchOffice" @focus="searchBranchOffice('')">
          <el-option v-for="item in branchOfficeList" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>

      </el-form-item>
      <el-form-item label="代理商类型">
        <el-select v-model="formInline.rootAgentType" placeholder="代理商类型" clearable filterable>
          <el-option :label="item.name" :value="item.id" v-for="(item,index) in rootAgentTypeList2" :key='index'></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="普通代理商等级" v-if="rootLevel">
        <el-select v-model="formInline.level" placeholder="普通代理商等级" clearable filterable>
          <el-option label="V0" value="0"></el-option>
          <el-option label="V1" value="1"></el-option>
          <el-option label="V2" value="2"></el-option>
          <el-option label="V3" value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="代理商名称">
        <el-input v-model="formInline.agentName" placeholder="代理商名称" clearable></el-input>
      </el-form-item>
      <el-form-item label="姓名">
        <el-input v-model="formInline.realName" placeholder="姓名" clearable></el-input>
      </el-form-item>
      <el-form-item label="手机号">
        <el-input v-model="formInline.phone" placeholder="手机号" clearable></el-input>
      </el-form-item>
      <el-form-item class="search-button">
        <el-button type="primary" @click="search">查询</el-button>
      </el-form-item>
      <el-form-item class="search-button">
        <el-button type="info" @click="clearSearch">清空</el-button>
      </el-form-item>
    </el-form>
    <el-button type="primary" @click="addAgent" style="float:right;margin-bottom:10px;">新增代理商</el-button>
    <el-table stripe :data="tableData" border style="width: 100%">

      <el-table-column prop="createTime" label="创建时间" width="180">
        <template slot-scope="scope">
          <span>{{ $formatDate(scope.row.createTime, 'yyyy-MM-dd HH:mm:ss') }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="agentSid" label="代理商ID"> </el-table-column>
      <el-table-column prop="agentName" label="代理商名称" width="180"></el-table-column>
      <el-table-column prop="rootAgentType" label="代理商类型" width="160">
        <template slot-scope="scope">
          <span>{{rootAgentTypeList1[scope.row.rootAgentType||0] && rootAgentTypeList1[scope.row.rootAgentType||0].name}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="recommendAgentName" label="推荐代理商" width="120"></el-table-column>
      <!-- <el-table-column prop="vipLevel" label="代理商等级" width="120">
        <template slot-scope="scope">
          <span>{{levelFormat(scope.row.vipLevel)}}</span>
        </template>
      </el-table-column> -->

      <el-table-column prop="phone" label="手机号" width="180"></el-table-column>
      <el-table-column prop="realName" label="真实姓名" width="140"></el-table-column>
      <el-table-column prop="directAgentCount" label="直推下级" width="120">
        <template slot-scope="scope">
          {{scope.row.directAgentCount||0}}
        </template>
      </el-table-column>
      <el-table-column prop="shopCount" label="直推商户数量" width="120">
        <template slot-scope="scope">
          {{scope.row.shopCount||0}}
        </template>
      </el-table-column>
      <el-table-column prop="directTakeoutCount" label="直推订单数量" width="120">
        <template slot-scope="scope">
          {{scope.row.directTakeoutCount||0}}
        </template>
      </el-table-column>
      <el-table-column prop="rate" label="分润比例" width="120">
        <template slot-scope="scope">
          {{scope.row.rate?(((+scope.row.rate)*100).toFixed(2)*1)+'%':'0%'}}
        </template>
      </el-table-column>
      <el-table-column prop="balance" label="可提现收益" width="120">
        <template slot-scope="scope">
          {{scope.row.balance||0}}
        </template>
      </el-table-column>
      <el-table-column prop="areaLabels" label="代理商区域" width="180" show-overflow-tooltip></el-table-column>
      <el-table-column prop="state" label="启用状态" width="120" align="center">
        <template slot-scope="scope">
          <span :style="{color:scope.row.state==1?'green':'#f22424'}">{{ scope.row.state==1?'启用':'禁用' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="state" label="操作" width="200" fixed="right">
        <template slot-scope="scope">
          <div>
            <span :class="{topactive:scope.row.preAgentCount>0}" @click="lookTopLevel(scope.row)">查看上级</span>
            <el-divider direction="vertical"></el-divider>
            <span :class="{bottomactive:scope.row.subAgentCount>0}" @click="lookBotLevel(scope.row)">查看下级</span>
            <el-divider direction="vertical"></el-divider><span style="color: #f22424;cursor: pointer;"
              @click="editAgent(scope.row)">编辑</span>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination class="mt10" background :page-count="totalPage" :current-page.sync="currentPage" @current-change="pageChange">
    </el-pagination>
    <!-- 新增编辑代理商 -->
    <el-dialog :title="agentAddOrEdit" :visible.sync="dialogFormVisible" width=" 630px" class='agentDialog'>
      <el-form :model="form">
        <el-form-item label="代理商类型" :label-width="formLabelWidth" v-if="addAgentForm.rootAgentTypes!=0">
          <el-radio-group v-model="addAgentForm.rootAgentType">
            <el-radio :label="3" :value='3'>联合创始人</el-radio>
            <el-radio :label="5" :value='5'>合伙人</el-radio>
            <el-radio :label="2" :value='2'>服务商</el-radio>
            <el-radio :label="1" :value='1'>代理商</el-radio>
            <el-radio :label="4" :value='4'>创业代理</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="推荐代理" :label-width="formLabelWidth">

          <el-select v-model="addAgentForm.recommendAgentSid" placeholder="请选择推荐代理商" clearable filterable remote :loading="loading"
            :remote-method="recommendAgent" @focus="recommendAgent('')" :disabled='addOrEditState'>
            <el-option v-for="item in recommendAgentList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <!-- <el-input v-model="addAgentForm.recommendAgentName"  size="normal" v-if="addOrEditState" :disabled='true'>
            </el-input> -->
        </el-form-item>
        <el-form-item label="代理名称" :label-width="formLabelWidth">
          <el-input v-model="addAgentForm.agentName" autocomplete="off" placeholder="请输入代理名称"></el-input>
        </el-form-item>
        <el-form-item label="姓名" :label-width="formLabelWidth">
          <el-input v-model="addAgentForm.realName" autocomplete="off" placeholder="请输入用户姓名"></el-input>
        </el-form-item>
        <el-form-item label="登录账号" :label-width="formLabelWidth">
          <el-input v-model="addAgentForm.phone" autocomplete="off" placeholder="请输入用户手机号作为登录账号"></el-input>
        </el-form-item>
        <el-form-item label="账号密码" :label-width="formLabelWidth">
          <el-input v-model="addAgentForm.password" autocomplete="off" placeholder="请输入密码"></el-input>
        </el-form-item>
        <el-form-item label="是否升级代理商" :label-width="formLabelWidth" v-if="addAgentForm.rootAgentTypes==0">
          <el-switch v-model="addAgentForm.ifUpgradeAgent" active-color="#ff4949">
          </el-switch>
        </el-form-item>
        <el-form-item label="代理商类型" :label-width="formLabelWidth" v-if="addAgentForm.ifUpgradeAgent==1&&addAgentForm.rootAgentTypes==0">
          <el-radio-group v-model="addAgentForm.rootAgentType">
           <el-radio :label="3" :value='3'>联合创始人</el-radio>
            <el-radio :label="5" :value='5'>合伙人</el-radio>
            <el-radio :label="2" :value='2'>服务商</el-radio>
            <el-radio :label="1" :value='1'>代理商</el-radio>
            <el-radio :label="4" :value='4'>创业代理</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="分润比例" :label-width="formLabelWidth">
          <el-input v-model="addAgentForm.rate" autocomplete="off" placeholder="分润比例"
            :disabled='addAgentForm.rootAgentTypes > 0 || addAgentForm.ifUpgradeAgent==1'
            style="width:95%"></el-input><span style="float:right">%</span>
        </el-form-item>
        <el-form-item label="归属分公司" :label-width="formLabelWidth">
          <el-select v-model="addAgentForm.spreadId" placeholder="请选择分公司" clearable filterable remote :loading="loading"
            :remote-method="searchBranchOffice" @focus="searchBranchOffice('')" @change='spreadChang()' :disabled='addOrEditState'>
            <el-option v-for="item in branchOfficeList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <!-- <el-input v-model="addAgentForm.spreadName" placeholder="" size="normal" v-if="addOrEditState" :disabled='true'>
            </el-input> -->
        </el-form-item>
        <el-form-item label="归属员工" :label-width="formLabelWidth" v-if='!staffState'>
          <el-select v-model="addAgentForm.companyStaffId" placeholder="请选择归属员工" clearable filterable remote :loading="loading"
            :remote-method="searchStaffList" @focus="searchStaffList('')" :disabled='addOrEditState'>
            <el-option v-for="item in staffList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <!-- <el-input v-model="addAgentForm.companyStaffName" placeholder="" size="normal" v-if="addOrEditState" :disabled='true'>
             </el-input> -->
        </el-form-item>

        <el-form-item label="起提金额" :label-width="formLabelWidth">
          <el-input v-model="addAgentForm.lifting" size="normal" clearable class="mini-input" type="number" placeholder="起提金额,不填默认是1元" />
          <span style="padding-left:5px">元</span>
        </el-form-item>
        <el-form-item label="提现手续费" :label-width="formLabelWidth">
          <el-input v-model="addAgentForm.poundagePercent" size="normal" class="mini-input" type="number" placeholder="提现手续费,不填默认是0.2元" />
          <span style="padding-left:5px">%</span>
        </el-form-item>
        <el-form-item label="选择区域" :label-width="formLabelWidth" v-if="areaState">
          <el-cascader v-model='addAgentForm.checkAreas' style="width: 360px" :options="options" :props="props" collapse-tags clearable>
          </el-cascader>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <PrevReclick>
          <el-button type="primary" @click="sureSubmit">确 定</el-button>
        </PrevReclick>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  $agent_selectAgent, $agent_addAgentOne,
  $agent_agentV2sByKey, $agent_staffsByKey, $agent_agentUserDetail, $agent_updateAgentOne, $agent_selectPreAgentPlat, $sp_areaTree,
} from '@/api/agentAll';
import { $sp_spreadList } from '@/api/order';
// eslint-disable-next-line no-unused-vars
import Dialog from '@/components/common/dialog.vue';

const rootAgentTypeList1 = [{ id: 0, name: '普通代理商' }, { id: 1, name: '代理商' }, { id: 2, name: '服务商' }, { id: 3, name: '联合创始人' }, { id: 4, name: '创业代理' }, { id: 5, name: '合伙人' }];

const rootAgentTypeList2 = [{ id: 0, name: '普通代理商' }, { id: 1, name: '代理商' }, { id: 2, name: '服务商' }, { id: 3, name: '联合创始人' }, { id: 4, name: '创业代理' }, { id: 5, name: '合伙人' }];
const orderStateList = [
  { value: '1', name: '待接单' },
  { value: '2', name: '骑手待接单' },
  { value: '3', name: '待收货' },
  { value: '4', name: '配送完成' },
  { value: '5', name: '撤销' },
];

export default {

  data() {
    return {
      areaState: true,
      props: { multiple: true },
      options: [
      ],
      rootLevel: false,
      spreadId: '',
      loading: false,
      lifting: 0,
      poundagePercent: 0,
      staffSpreadId: null,
      staffState: true,
      rootAgentTypeList1,
      rootAgentTypeList2,
      formLabelWidth: '120px',
      dialogFormVisible: false,
      branchOfficeList: [],
      recommendAgentList: [],
      staffList: [],
      formInline: {
        spreadId: '',
        level: '',
        agentName: '',
        rootAgentType: '',
        phone: '',
        realName: '',
      },
      form: {},
      orderStateList,
      startTime: '',
      endTime: '',
      totalPage: 1,
      currentPage: 1,
      tableData: [],
      endTimeStatus: true,
      agentAddOrEdit: '编辑代理商',
      addOrEditState: false,
      addAgentForm: {
        rate: '',
        rootAgentType: 0,
        companyStaffId: '',
        recommendAgentSid: null,
        agentName: '',
        lifting: '',
        poundagePercent: '',
        realName: '',
        password: '',
        phone: '',
        vipTemplateId: 1,
        spreadId: null,
        ifUpgradeAgent: false,
        checkAreas: null,
      },
      staglist: {

      },
    };
  },
  computed: {},
  watch: {
    'addAgentForm.rootAgentType': {
      handler(value) {
        if (value) {
          const valueList = [0, 70, 80, 90, 65, 85];
          this.addAgentForm.rate = valueList[value];
        }
      },
    },
    'addAgentForm.spreadId': {
      handler(value) {
        if (value) {
          this.staffSpreadId = value;
          this.staffState = false;
          this.staglist.spreadId = value;
        } else {
          this.staffSpreadId = value;
          this.staffState = true;
          this.staglist.spreadId = value;
        }
      },
    },
    'addAgentForm.recommendAgentSid': {
      handler(value) {
        this.staglist.recommendAgentSid = value;
      },
    },
    'addAgentForm.companyStaffId': {
      handler(value) {
        console.log('333', value, 333);
        this.staglist.companyStaffId = value;
      },
    },
    'formInline.rootAgentType': {
      handler(value) {
        if (value === 0) {
          this.rootLevel = true;
        } else {
          this.rootLevel = false;
        }
      },
    },
    'addAgentForm.checkAreas': {
      handler(value) {
        console.log(value, 'area');
      },
    },
    immediate: true,
  },
  created() {
    this.getTableData();
  },
  mounted() {
    this.areaTree();
  },
  // 方法集合
  methods: {

    // 获取区域树状图
    areaTree() {
      $sp_areaTree().then((res) => {
        this.options = res.children;
      });
    },
    getCheckListByLastAreaId(areaIds) {
      const selectItems = [];
      this.options.forEach((country) => {
        if (!country.children) {
          console.log(country);
          return;
        }
        country.children.forEach((city) => {
          if (areaIds.includes(city.value)) {
            selectItems.push([country.value, city.value]);
          } else if (city.children) {
            city.children.forEach((dit) => {
              if (areaIds.includes(dit.value)) {
                selectItems.push([country.value, city.value, dit.value]);
              }
            });
          }
        });
      });
      console.log(selectItems, 'selectItems');
      return selectItems;
    },
    // 查看上下级
    lookTopLevel(item) {
      if (item.preAgentCount > 0) {
        $agent_selectPreAgentPlat({
          pageCurrent: 1,
          pageSize: 10,
          agentSid: item.agentSid,
        }).then((res) => {
          this.tableData = res.records;
          this.tableData.forEach((op) => {
            Object.assign(op, op.agentV2StatisticsVo);
          });
          this.totalPage = Number(res.pages) || 1;
        });
      }
    },
    lookBotLevel(item) {
      console.log(item, 'dfdsf');
      if (item.subAgentCount > 0) {
        $agent_selectAgent({
          pageCurrent: 1,
          pageSize: 10,
          agentSid: item.agentSid,
        }).then((res) => {
          this.tableData = res.records;
          this.tableData.forEach((op) => {
            Object.assign(op, op.agentV2StatisticsVo);
          });
          this.totalPage = Number(res.pages) || 1;
        });
      }
    },
    levelFormat(item) {
      // eslint-disable-next-line eqeqeq
      if (item == '0') {
        return 'V0';
      }
      // eslint-disable-next-line eqeqeq
      if (item && item != 0) {
        return `V${item}`;
      } else {
        return '--';
      }
    },
    // 代理商选择
    spreadChang() {
      console.log('345678');
      this.$nextTick(() => {
        this.addAgentForm.companyStaffId = '';
      });
    },
    // 归属员工
    async searchBranchOffice(query) {
      this.loading = true;
      if (query) {
        await this.reviewedShopPage(query);
      } else {
        await this.reviewedShopPage('');
      }
      this.loading = false;
    },
    async recommendAgent(query) {
      this.loading = true;
      if (query) {
        await this.recommendAgentPage(query);
      } else {
        await this.recommendAgentPage('');
      }
      this.loading = false;
    },
    // searchStaffList
    async searchStaffList(query) {
      this.loading = true;
      if (query) {
        await this.StaffListPage(query);
      } else {
        await this.StaffListPage('');
      }
      this.loading = false;
    },
    StaffListPage(key = '') {
      let params;
      if (this.addOrEditState) {
        params = {
          key, spreadId: this.staglist.spreadId,
        };
      } else {
        params = {
          key, spreadId: this.staffSpreadId,
        };
      }

      return $agent_staffsByKey(params).then((res) => {
        const tempArr = [];
        res.forEach((item) => {
          const json = {
            value: item.id,
            label: item.name,
          };
          tempArr.push(json);
        });
        this.staffList = tempArr;
      });
    },
    // 推荐代理商
    recommendAgentPage(key = '') {
      const params = {
        key,
      };
      return $agent_agentV2sByKey(params).then((res) => {
        const tempArr = [];
        res.forEach((item) => {
          const json = {
            value: item.id,
            label: item.name,
          };
          tempArr.push(json);
        });
        this.recommendAgentList = tempArr;
      });
    },
    // 请求归属分公司数据
    reviewedShopPage(platName = '') {
      const params = {
        platName,
        pageSize: 200,
      };
      return $sp_spreadList(params).then((res) => {
        const tempArr = [];
        res.forEach((item) => {
          const json = {
            value: item.spreadId,
            label: item.platName,
          };
          tempArr.push(json);
        });
        this.branchOfficeList = tempArr;
      });
    },
    editAgent(item) {
      this.agentAddOrEdit = '编辑代理商';
      this.addOrEditState = true;
      // eslint-disable-next-line eqeqeq
      if (item.rootAgentType == 0) {
        this.areaState = false;
      } else {
        this.areaState = true;
      }
      $agent_agentUserDetail(item.agentSid).then((res) => {
        this.addAgentForm = res;
        const { companyStaffId, recommendAgentSid, spreadId } = this.addAgentForm;
        setTimeout(() => {
          this.staglist = {
            companyStaffId,
            recommendAgentSid,
            spreadId,
          };
        }, 0);
        this.addAgentForm.rootAgentTypes = this.addAgentForm.rootAgentType;
        this.addAgentForm.companyStaffId = this.addAgentForm.companyStaffName;
        this.addAgentForm.recommendAgentSid = this.addAgentForm.recommendAgentName;
        this.addAgentForm.spreadId = this.addAgentForm.spreadName;
        this.addAgentForm.checkAreas = this.getCheckListByLastAreaId(this.addAgentForm.areaIds);
        this.dialogFormVisible = true;
      });
    },
    addAgent() {
      this.addOrEditState = false;
      this.addAgentForm = {
        agentSid: '',
        companyStaffId: '',
        recommendAgentSid: null,
      };
      this.dialogFormVisible = true;
      this.addAgentForm.rootAgentTypes = 1;
      this.agentAddOrEdit = '新增代理商';
    },
    sureSubmit() {
      const addAgentRegular = /^.{1,20}$/;
      const phoneRegular = /^\d{11}$/;
      const addAgentUserRegular = /^.{2,14}$/;
      const pswRegular = /^\w{6,18}$/;
      if (!(`${this.addAgentForm.rootAgentType}`)) {
        this.$message.error('请选择代理商类型');
        return;
      }
      if (!addAgentUserRegular.test(this.addAgentForm.agentName)) {
        this.$message.error('请输入代理商名称,2-14个字以内');
        return;
      }
      if (!addAgentRegular.test(this.addAgentForm.realName)) {
        this.$message.error('请输入用户姓名,1-20个字以内');
        return;
      }
      if (!phoneRegular.test(this.addAgentForm.phone)) {
        this.$message.error('请输入正确的用户手机号');
        return;
      }
      if (this.addAgentForm.password) {
        if (!pswRegular.test(this.addAgentForm.password)) {
          this.$message.error('请输入密码,6-18个字以内');
          return;
        }
      }
      if (!phoneRegular.test(this.addAgentForm.phone)) {
        this.$message.error('请输入正确的用户手机号');
        return;
      }
      if (this.areaState) {
        if (!(this.addAgentForm.checkAreas && this.addAgentForm.checkAreas.length)) {
          this.$message.error('请选择区域');
          return;
        }
      }

      //  /^(\d{1,10})|([0-9]{1,7}\.\d{1,2})$/
      const limitNum = /^(([1-9]{1}\d*)|(0{1}))(\.\d{0,2})?$/;
      console.log(this.addAgentForm.lifting, 'woerir');
      if (this.addAgentForm.lifting) {
        const formatState = limitNum.test(this.addAgentForm.lifting);
        if (!formatState || this.addAgentForm.lifting < 1) {
          this.$message.error('请输入正确的起提金额,大于等于1且至多保留两位小数');
          return;
        }
      }
      // eslint-disable-next-line eqeqeq
      if ((this.addAgentForm.poundagePercent) && (this.addAgentForm.poundagePercent != 0)) {
        const poundagePercentNum = /^(([1-9]{1}\d*)|(0{1}))(\.\d{0,2})?$/;
        const formatState = poundagePercentNum.test(this.addAgentForm.poundagePercent);
        if (!formatState || this.addAgentForm.poundagePercent > 35) {
          this.$message.error('请输入正确的提现手续费,小于35至多保留两位小数');
          return;
        }
      }
      // 区域数组判断 checkAreas
      this.addAgentForm.areaIds = [];
      this.addAgentForm.checkAreas.forEach((item) => {
        if (item && item.length) {
          this.addAgentForm.areaIds.push(item[item.length - 1]);
        }
      });
      Reflect.deleteProperty(this.addAgentForm, 'checkAreas');
      if (this.addOrEditState) {
        this.addAgentForm.ifUpgradeAgent = Number(this.addAgentForm.ifUpgradeAgent);
        const param = JSON.parse(JSON.stringify(this.addAgentForm));
        param.companyStaffId = this.staglist.companyStaffId;
        param.recommendAgentSid = this.staglist.recommendAgentSid;
        param.spreadId = this.staglist.spreadId;

        $agent_updateAgentOne(param).then(() => {
          this.getTableData();
          this.dialogFormVisible = false;
        });
      } else {
        console.log(this.addAgentForm, 'woshi hahha ');
        $agent_addAgentOne(this.addAgentForm).then(() => {
          this.getTableData();
          this.dialogFormVisible = false;
        });
      }
    },
    // 时间框选中
    selectDate() {
      console.log(123);
    },
    // 結束时间
    onChange() {
      // this.endTimeStatus = true;
    },


    // 清空搜索
    clearSearch() {
      this.formInline = {
        spreadId: '',
        level: '',
        agentName: '',
        rootAgentType: '',
        phone: '',
        realName: '',
      };
      $agent_selectAgent({
        pageCurrent: 1,
        pageSize: 10,
      }).then((res) => {
        this.currentPage = 1;
        this.tableData = res.records;
        this.tableData.forEach((item) => {
          Object.assign(item, item.agentV2StatisticsVo);
        });
        this.totalPage = Number(res.pages) || 1;
      });
    },
    // 时间戳处理
    formatTime({ shareProfitTime }) {
      return this.$formatDate(shareProfitTime, 'yyyy-MM-dd HH:mm');
    },
    // 分页逻辑
    pageChange() {
      $agent_selectAgent({
        ...this.formInline,
        pageCurrent: this.currentPage,
        pageSize: 10,
      }).then((res) => {
        this.tableData = res.records;
        this.tableData.forEach((item) => {
          Object.assign(item, item.agentV2StatisticsVo);
        });
        this.totalPage = Number(res.pages) || 1;
      });
    },

    // 搜索查询
    search() {
      $agent_selectAgent({
        ...this.formInline,
        pageCurrent: 1,
        pageSize: 10,
      }).then((res) => {
        this.tableData = res.records;
        this.tableData.forEach((item) => {
          Object.assign(item, item.agentV2StatisticsVo);
        });
        this.totalPage = Number(res.pages) || 1;
      });
    },
    // tabel 数据获取
    getTableData() {
      $agent_selectAgent({
      }).then((res) => {
        this.tableData = res.records;
        this.tableData.forEach((item) => {
          Object.assign(item, item.agentV2StatisticsVo);
        });
        this.totalPage = Number(res.pages) || 1;
      });
    },


  },
};
</script>
<style lang='scss' >
.share-profits-deatail {
  // .el-cascader__tags {
  //   max-height: 160px;
  //   overflow: auto;
  // }
  .el-radio {
    padding-bottom: 10px;
  }
  .topactive {
    color: #f22424;
    cursor: pointer;
  }
  .bottomactive {
    color: #f22424;
    cursor: pointer;
  }
  .money-explain {
    width: 100%;
    margin-top: 20px;
    text-align: right;
    color: #f22424;
    cursor: pointer;
    span {
      padding-right: 5px;
    }
    img {
      vertical-align: middle;
    }
  }
  padding: 40px 20px;
  background: #ffffff;
  border-radius: 6px;
  .el-form-item__label {
  }
  .agentDialog {
    .el-input {
      height: 32px !important;
      line-height: 32px !important;
      // width: 90%!important;
    }
  }
  .demo-form-inline {
    .el-input__inner {
      height: 32px !important;
      line-height: 32px !important;
    }
    .el-form-item__label {
      line-height: 36px;
    }
    .el-form-item__content {
      line-height: 32px;
      width: 130px;
    }
    .el-form-item {
      margin-right: 20px;
    }
  }

  .search-button {
    margin-left: 10px;
    margin-right: 10px !important;
    .el-button {
      width: 80px;
    }
    .el-form-item__content {
      width: 80px;
    }
  }
  .agentDialog {
    .el-dialog__body {
      padding: 20px 80px 20px 60px;
    }
  }
  .mini-input {
    width: 60% !important;
    //margin-left: 30px;
  }
}
</style>
